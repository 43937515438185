export class qParams {
    
    /* 
        Spaced Repetition Stage
        
        0: new // never trialed
        0: relearn 
        1: fixedInterval-1 // fixed interval = 1, EF not considered, trail_count > 0 
        2: fixedInterval-3 // fixed interval = 2,3, EF not considered
        3: review , interval calculated
    */
    srsstage:string;
    
    /* 
    Session stage
        notTrialed
        trailFailed
        trailSuccess
        reschedule
    */
    sStage:string;

    //interval
    ivl:number;

    constructor(srsstage:string,ivl:number){
        this.srsstage = srsstage;
        this.ivl = ivl;
    }

}