import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'naturalHowSoon'
})
export class NaturalHowSoonPipe implements PipeTransform {

    transform(time: { value: number, unit: string }): any {
        if (!time) {
            return time;
        }

        const value = +time.value;
        const unit = time.unit;
        if (unit === 'day') {
            if (value === 1) {
                return value + ' dzień';
            } else if (value > 1 && value < 30) {
                return value + ' dni';
            } else if (value >= 30 && value < 365) {
                return this._makeF(value, 'month') + ' msc.';
            } else if (value >= 365 && value < 730) {
                return this._makeF(value, 'year') + ' roku';
            } else if (value >= 730) {
                return this._makeF(value, 'year') + ' lat';
            }
        } else if (unit === 'minute') {
            return value + ' minut';
        }
    }

    /*TODO: improve name*/
    _makeF(value: number, period: string) {

        let res;
        switch (period) {
            case 'month':
                res = Math.round(value / 30 * 10) / 10;
                break;
            case 'year':
                res = Math.round(value / 365 * 10) / 10;
                break;

        }

        const resString = res.toString();
        return resString.replace('.', ',');

    }
}
