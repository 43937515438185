export class Utils {
    public static isOnline() {
        // return false;
        return window.navigator.onLine;
    }

    public static getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    public static shuffleArray(a) {
        let j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    public static isSameWord(a, b) {
        a = a.toLowerCase().trim();
        b = b.toLowerCase().trim();

        return a === b;
    }
}
