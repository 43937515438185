import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthReqChecker} from '../classes/AuthReqChecker';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {UserService} from './user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private _injector: Injector
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!AuthReqChecker.instance.isExcludedFromAuth(req.url)) {
            const authReq = req.clone({
                headers: req.headers.set('authtoken', UserService.TOKEN)
                // params: req.params.set('jwt', UserService.TOKEN)
            });

            return next.handle(authReq).pipe(catchError(err => {
                if (err.status === 401) {
                    this._onUnauthorized();
                }

                let error = err;
                if (err.error) {
                    error = err.error.message;
                } else if (err.statusText) {
                    error = err.statusText;
                }

                return throwError(error);

            }), map((event: HttpResponse<any>) => {
                if (event.body && event.body.success === 0 && event.body.data === 'Access denied ') {
                    this._onUnauthorized();
                }
                return event;
            }));
        }

        return next.handle(req);
    }

    private _onUnauthorized() {
        const userService = this._injector.get(UserService);
        userService.logout();
        location.reload(true);
    }
}
