import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
    selector: 'app-learnhistory-modal',
    templateUrl: './learnhistory-modal.component.html',
    styleUrls: ['./learnhistory-modal.component.scss']
})
export class LearnhistoryModalComponent implements OnInit {
    @Input() card: any;

    constructor(
        public modalController: ModalController,
        public navParams: NavParams,
    ) { }

    ngOnInit() {
        // console.log(this.navParams.data.trialLog);


    }


    closeModal() {
        this.modalController.dismiss();
    }

}
