import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'url-search-params-polyfill';
import {environment} from '../../environments/environment';

export interface ApiResponse {
    success: boolean;
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor (
        private _http: HttpClient
    ) {

    }
    /**
     *
     * @param method
     * @param endPoint
     * @param urlParams
     * @param data
     * @returns {Promise<any>}
     * @private
     */
    _makeRequest (
        method: string,
        endPoint: string,
        urlParams: any = {},
        data: any = null
    ): Promise<any> {
        const params = new URLSearchParams(urlParams);
        let URL = environment.APIEndPoint + endPoint;

        const paramsAsString = params.toString();
        if (paramsAsString) {
            URL += '?' + paramsAsString;
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        });

        return new Promise((resolve, reject) => {
            this._http.request<ApiResponse|string>(method, URL, {
                headers,
                body: data ? JSON.stringify(data) : undefined
            }).subscribe(
                (response) => {
                    if (typeof(response) === 'string') {
                        response = JSON.parse(response) as ApiResponse;
                    }

                    return resolve(response.data || response['0']);

                },
                (response: any) => {
                    if (response && response.error) {
                        response = response.error;
                        if (typeof(response) === 'string') {
                            response = JSON.parse(response);
                        }
                    }

                    console.log('Connection error', response);
                    reject(response);
                }
            );
        });
    }

    get (
        endPoint: string,
        urlParams: any = {},
    ): Promise<any> {
        return this._makeRequest(
            'GET',
            endPoint,
            urlParams,
            null,
        );
    }

    post (
        endPoint: string,
        postParams: any = {},
        urlParams: any = {},
    ): Promise<any> {
        return this._makeRequest(
            'POST',
            endPoint,
            urlParams,
            postParams,
        );
    }

    put (
        endPoint: string,
        postParams: any = {},
        urlParams: any = {},
    ): Promise<any> {
        return this._makeRequest(
            'PUT',
            endPoint,
            urlParams,
            postParams,
        );
    }

    delete (
        endPoint: string,
        urlParams: any = {},
    ): Promise<any> {
        return this._makeRequest(
            'DELETE',
            endPoint,
            urlParams,
            null,
        );
    }
}


