import {Injectable} from '@angular/core';

import {Storage} from '@ionic/storage';
import * as moment from 'moment';
import {SetsService} from './services/sets.service';
import {CoursesService} from './services/courses.service';
import { ProfileService } from './services/profile.service';

const SESSION_POINTS_PREFIX = 'session_points_'

@Injectable()

export class storageService {

    public static MYSQL_DATETIME = 'YYYY-MM-DD HH:mm:ss';
    public static MYSQL_DATE = 'YYYY-MM-DD';

    

    wordsArr: object[] = [];

    sets;

    // public static now: Date;

    saveQ;

    constructor(
        private _setsService: SetsService,
        // private _coursesService: CoursesService,
        private storage: Storage,
        private _ProfileService: ProfileService
    ) {

    }

    // /**
    //  * @deprecated
    //  * leaving for compatibility reasons
    //  *
    //  * USE: CoursesService.getSetsList
    //  *
    //  * @param courseID
    //  */
    // getSets(courseID) {
    //     return this._coursesService.getSetsList(courseID);
    // }


    get(key){
        return this.storage.get(key)
    }

    set(key,val){
        return this.storage.set(key,val);
    }


    getSet(setID) {
        return this.storage.get(setID).then(
            (val) => {
                return JSON.parse(val);
            }
        );
    }

    setData(key, data) {

        try {
            return this.storage.set(key, JSON.stringify(data)).then((res) => {


            },
            (error)=> {
                console.log('error for key',key,error);
                
            }
        )
        } catch (error) {
            console.log('error for key',key);
            
        }
        

    }

    
    flushProgress(){
        return this._ProfileService.flushProgressQ();
    }

    saveAnswer(cardObj,course) {

        // const set = this._setsService.getById(cardObj.setID);
        
        // if (set) {
        //     const item = set.content.find(itm => itm.word.ID === cardObj.cardID);
        //     if (item) {
              
                var saveData = {
                    courseID: course ,
                    setID: cardObj.setID ,
                    ID_slowka: cardObj.cardID,
                    answerType: cardObj.answerType,
                    sTrial: cardObj.trial,
                    srsstage: cardObj.srsstage,
                    sstage: cardObj.sstage,
                    ef: cardObj.EF,
                    interwal: cardObj.ivl,
                    nextTrialTime: moment(cardObj.nextTrialTime).format('YYYY-MM-DD HH:mm:ss'),
                    lastTrialTime: moment(cardObj.lastTrialTime).format('YYYY-MM-DD HH:mm:ss'),
                    profileEventLog: cardObj.profileEventLog,
                }


                // item.learnHistory.unshift(saveData);

                // this._setsService.storeSet(set)
                //     .then(() => {
                        
                //     });
                
                this._ProfileService.addProgress(saveData);

                
            // }
        // }
    }

    private _getSessionPointsDate(){
        return moment().format(storageService.MYSQL_DATE);
    }

    private _getSessionPointKey(courseID){
        return SESSION_POINTS_PREFIX + courseID;
    }

    storeSessionPointsObj(courseID,storeDataObj){
        
        this.storage.set(this._getSessionPointKey(courseID),storeDataObj);

    }

    storeSessionPointsForToday(courseID,pointsCount){
        var storeData = new Object();
        storeData[this._getSessionPointsDate()] = pointsCount;
        this.storage.set(this._getSessionPointKey(courseID),storeData);
    }


    getSessionPointsForToday(courseID){
        return this.storage.get(this._getSessionPointKey(courseID)).then((results) => {
            
            if(results){
                var pointsArr = results;
                if(pointsArr[this._getSessionPointsDate()]){
                    return pointsArr[this._getSessionPointsDate()];
                }
            }
            
            return 0;
        },()=>{
            return 0;
        }).catch((error)=>{
            console.log('Error getting points',error);
            return 0;
            
        })
        
    }


}