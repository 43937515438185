/**
 * Class that holds information about urls and checks if they qualify for authentication
 */
import {environment} from '../../environments/environment';

export class AuthReqChecker {
    private static _instance: AuthReqChecker;

    private _base = '';
    private _excludedUrls: Array<string> = [
        '/auth/login'
    ];

    private constructor (baseUrl: string) {
        if (baseUrl.indexOf('?')) {
            this._base = baseUrl.split('?')[0];

        } else {
            this._base = baseUrl;
        }
    }

    static get instance() {
        if (!AuthReqChecker._instance) {
            AuthReqChecker._instance = new AuthReqChecker(environment.APIEndPoint);
        }

        return AuthReqChecker._instance;
    }

    public isExcludedFromAuth (url) {
        if (url.indexOf(this._base) === -1) {
            return true;

        } else {
            for (const endpoint of this._excludedUrls) {
                if (url.indexOf(endpoint) > -1) {
                    return true;
                }
            }

            return false;
        }
    }

}
