const BASE_URL = 'https://moje.english-line.pl';

export const environment = {
    production: true,
    BaseUrl: BASE_URL,
    APIEndPoint: BASE_URL + '/api/',
    // audioFiles: 'https://moje.english-line.pl/voice/'
    audioFiles: BASE_URL + '/api/audio/get/',
    OneSignalKey: '57e63e91-8ab0-44e8-943f-52c25360ae40',
    version: '0.6.0'
};
