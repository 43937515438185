import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Storage } from '@ionic/storage';
import { ReplaySubject } from 'rxjs';
import { ProfileService } from './profile.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class UserService {
    public static TOKEN = null;
    public authenticated$ = new ReplaySubject(1);
    public authenticated = false;

    constructor(
        private _storage: Storage,
        private _api: ApiService,
        private _profile: ProfileService,
        @Inject(DOCUMENT) private _document: Document
    ) {

    }

    public initialize(): Promise<void> {
        return this._loadToken()
            .then((authtoken) => {
                if (authtoken) {
                    this._onAuthSuccess(authtoken);
                }
            });
    }

    public authenticate(login: string, password: string) {
        return this._api.post('auth/login', {
            login,
            password
        }).then((token) => {
            if (token) {
                this._onAuthSuccess(token);
                return true;
            }

            return false;
        });
    }

    public logout() {
        this._profile.removeUserIdInOneSignal();
        return this._storage.clear();
        // return this._api.get('auth/logout');
    }

    private _onAuthSuccess(token) {
        UserService.TOKEN = token;
        this.authenticated = true;
        this._storage.set('authtoken', token);

        this.authenticated$.next(true);
        this.authenticated$.complete();
    }

    private _loadToken() {
        const token = this._tokenFromUrl();
        if (token) {
            return Promise.resolve(token);
        }

        return this._storage.get('authtoken');

    }

    private _tokenFromUrl() {
        const params = this._document.location.search;
        const urlParams = new URLSearchParams(params);

        return urlParams.get('r-token');
    }
}
