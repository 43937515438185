import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'mask'
})
export class MaskPipe implements PipeTransform {

    transform(value: string, maskWord?: any): any {
        if (value && maskWord) {
            const temp = value.split('');
            const start = value.indexOf('>');
            const end = value.lastIndexOf('<');

            return temp.map((elem, index) => {
                if (index > start && index < end) {
                    return '_';

                } else {
                    return elem;
                }
            }).join('');

        } else {
            return value;
        }
    }
}
