import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'Percent'
})
export class PercentPipe implements PipeTransform {

    transform(num: number): any {
        const dec = 2;

        if ((typeof num !== 'number') || (typeof dec !== 'number')) {
            return false;
        }

        const num_sign = num >= 0 ? 1 : -1;

        return (Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec) + '%';

    }
}
