import { card } from "./card";
import * as moment from 'moment';
import { button } from "./button";
import { learnTrial } from "./trial";
import { storageService } from "../storaga.service";

export enum DeckStatus {
    BUILD,
    LEARNT
}


export class deck {

    cards: Array<card> = [];

    totalTrials;

    // now:Date;

    dayTrialLog: Array<learnTrial> = [];
    trialEvents = [];

    courseID: number;

    next: card;

    sessionPointsCount: number = 0;

    revisionArr = [];
    newWordsArr = [];
    duringLearningNewArr = [];
    duringLearningRevArr = [];
    newWordLearnedArr = [];
    revisionWordLearnedArr = [];
    newWordLearnCount = 0;
    revisionWordLearnCount = 0;

    sets;

    constructor(courseID,
        // nowDate,
        private storage,
        private _profile,
        private _courses
    ) {

        // this.now = nowDate;
        this.courseID = courseID;


    }

    build() {

        return this._courses.getSetsList(this.courseID)
            .then((sets) => {
                if (sets) {
                    this.sets = sets;
                    this.storage.getSessionPointsForToday(this.courseID).then((points) => {
                        this.sessionPointsCount = points;
                    });
                    return this.setupDeck(sets);

                } else {
                    return Promise.reject();
                }

            }).catch((failure) => {
                console.log('error building deck: ' + failure);

                return failure;
            });
    }

    // private _getPointsFromToday(){
    //     const today:string = moment(new Date()).format(storageService.MYSQL_DATE);
    //     return this.storage.getSessionPointsForToday(today);
    // }

    setupDeck(sets): DeckStatus {

        // let revisionArr = [];
        // let newWordsArr = [];
        // let duringLearningNewArr = [];
        // let duringLearningRevArr = [];
        // let newWordLearnedArr = [];
        // let revisionWordLearnedArr = [];
        // let newWordLearnCount = 0;
        // let revisionWordLearnCount = 0;

        let lastTime = moment(1556783784, 'x');

        for (let index = 0; index < sets.length; index++) {
            const setObj = sets[index];


            if (setObj.content == null) {
                continue;
            }
            if (setObj.status !== 'visible') {
                continue;
            }


            for (let indexJ = 0; indexJ < setObj.content.length; indexJ++) {

                const wordObj = setObj.content[indexJ];
                wordObj['setID'] = setObj.id_listy;

                const newCard = new card(wordObj, this);

                if (!newCard.isNew()) {

                    const cardLast = newCard.getLastTrial().data.lastTrialTime;
                    if (cardLast.isAfter(lastTime)) {
                        lastTime = moment(cardLast);
                    }

                    if (newCard.isTrialedToday()) {
                        this.dayTrialLog.push(newCard.getLastTrial());

                        if (newCard.isLearnedOnce()) { // new word finished today
                            this.newWordLearnCount++;
                            this.newWordLearnedArr.push(newCard);
                            continue;
                        } else if (newCard.isLearned()) {// revision finished today
                            this.revisionWordLearnCount++;
                            this.revisionWordLearnedArr.push(newCard);
                            continue;
                        }

                    }

                    if (newCard.isNewAndLearning()) {
                        this.duringLearningNewArr.unshift(newCard);
                        continue;
                    } else if (newCard.isReq()) {
                        this.duringLearningRevArr.unshift(newCard);
                        continue;
                    }

                    if (newCard.scheduledForToday()) {
                        this.revisionArr.push(newCard);
                    }


                } else {
                    this.newWordsArr.push(newCard);
                }

            }

        }

        this._courses.updateTrialTime(lastTime.unix());

        this._sortLogByNewest(this.dayTrialLog);



        let cardsCount = 0;

        this.revisionArr = this.shuffleArr(this.revisionArr);
        this._sortFromOldest(this.revisionArr, 'nextTrialTime');



        const leftRevisions: number = this._profile.getProfileConfig().revisions_per_day - this.revisionWordLearnCount - this.duringLearningRevArr.length;
        let leftNewRevCards = 0;
        if (this.revisionArr.length < leftRevisions) {
            leftNewRevCards = this.revisionArr.length;
        } else {
            leftNewRevCards = leftRevisions;
        }
        leftNewRevCards = leftNewRevCards + this.duringLearningRevArr.length
        for (let index = 0; index < this.duringLearningRevArr.length; index++) {
            const element = this.duringLearningRevArr[index];
            this.revisionArr.unshift(element);
        }
        cardsCount = this.duringLearningRevArr.length;

        // if(leftRevisions > 0){


        // if(indexCount < duringLearningRevArr.length){
        //     indexCount = duringLearningRevArr.length;
        // }


        // cardsCount = cardsCount + revisionArr.length;
        for (let index = 0; index < leftNewRevCards; index++) {
            this.addCard(this.revisionArr[index], 'revision')
        }
        cardsCount = cardsCount + leftNewRevCards;
        // }

        const leftNewWords: number = this._profile.getProfileConfig().newwords_per_day - this.newWordLearnCount - this.duringLearningNewArr.length;
        let leftNewCards = 0;
        if (this.newWordsArr.length < leftNewWords) {
            leftNewCards = this.newWordsArr.length;
        } else {
            leftNewCards = leftNewWords;
        }
        for (let index = 0; index < this.duringLearningNewArr.length; index++) {
            const element = this.duringLearningNewArr[index];
            this.newWordsArr.unshift(element);
        }
        leftNewCards = leftNewCards + this.duringLearningNewArr.length;

        // if((leftNewWords > 0 && newWordsArr.length>0 ) || duringLearningNewArr.length>0){
        //     this._sortFromOldest(newWordsArr,'WordCreateTime');




        //   if(indexCount < duringLearningNewArr.length){
        //     indexCount = duringLearningNewArr.length;
        //   }


        for (let index = 0; index < leftNewCards; index++) {
            this.addCard(this.newWordsArr[index], 'new')
        }
        cardsCount = cardsCount + leftNewCards;

        // }


        if (cardsCount > 0) {
            this.shuffleArr(this.cards);
            this.updateSort();

            return DeckStatus.BUILD;
        } else {
            return DeckStatus.LEARNT;
        }

    }


    public shuffleArr(arr) {


        const shuffledDeck = arr;


        let j, x, i;
        for (i = shuffledDeck.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = shuffledDeck[i];
            shuffledDeck[i] = shuffledDeck[j];
            shuffledDeck[j] = x;

        }

        return shuffledDeck;
    }

    /**
     *
     * @param arr
     * @param byValue parameter to sort by
     */
    private _sortFromOldest(arr: Array<any>, byValue: string) {
        return arr.sort(
            (a, b) => {
                if (a[byValue].isBefore(b[byValue])) {
                    return -1
                } else {
                    return 1
                }

            }
        )
    }

    private _sortLogByNewest(arr: Array<learnTrial>) {
        return arr.sort(
            (a, b) => {
                if (a.data.lastTrialTime.isBefore(b.data.lastTrialTime)) {
                    return 1
                } else {
                    return -1
                }

            }
        )
    }

    sortDeck() {
        this.cards.sort(this.compareCards);
    }

    compareCards(a, b) {
        if (a.nextRepetitionTime < b.nextRepetitionTime)
            return -1;
        if (a.nextRepetitionTime > b.nextRepetitionTime)
            return 1;
        return 0;
    }



    addCard(card: card, type) {
        card.setup(type);
        this.cards.push(card);
    }

    // setNow(nowDate){
    //     this.now = nowDate;
    //     this.cards.forEach(card => {
    //         card.setNow(this.now);
    //     });
    // }

    newSession() {
        this.cards.forEach((card) => {
            card.sstage = 0;
            card.trial = 1;
        });
    }

    makeData() {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    trialsDone() {

        let trialsCount = 0;
        const counted = [];

        for (let index = 0; index < this.dayTrialLog.length; index++) {
            const trial: learnTrial = this.dayTrialLog[index];

            if (trial.data.sstage == card.SS_reschedule) {
                trialsCount++;
            }
            if (trial.data.sstage == card.SS_trialSuccess) {
                if (counted.includes(trial.data.ID_slowka) === false) {
                    trialsCount++;
                    counted.push(trial.data.ID_slowka);
                }
            }

        }


        return trialsCount;
    }


    leftTrialCount() {

        let leftTrialCount = 0;

        for (let index = 0; index < this.cards.length; index++) {
            const card = this.cards[index];


            leftTrialCount += card.getTrialsLeft();
        }

        return leftTrialCount;
    }


    public progressCounts() {

        const progress = {};
        progress['new'] = 0;
        progress['duringL'] = 0;
        progress['rev'] = 0;

        for (let index = 0; index < this.cards.length; index++) {

            if (this.cards[index].isNew()) {
                progress['new']++;
            } else if (this.cards[index].isNewAndLearning() || this.cards[index].isRevAndLearning()) {
                progress['duringL']++;
            } else {
                progress['rev']++;
            }

        }
        return progress;
    }


    getMaxCards() {
        let counterMax;
        if (this.cards.length > this._profile.getProfileConfig().revisions_per_day) {
            counterMax = this._profile.getProfileConfig().revisions_per_day;
        } else {
            counterMax = this.cards.length;
        }
        return counterMax;
    }

    getCards() {
        return this.cards;
    }



    updateSort() {


        this.cards.sort((a, b) => {

            const now = moment(new Date());

            if (a.isReQ || b.isReQ()) {
                if (a.isReQ() && b.isReQ()) {
                    if (a.nextTrialTime.isBefore(now) && b.nextTrialTime.isBefore(now)) {
                        if (a.nextTrialTime.isBefore(b.nextTrialTime)) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else if (a.nextTrialTime.isBefore(now)) {
                        return -1
                    } else if (b.nextTrialTime.isBefore(now)) {
                        return 1
                    } else {// none is before now
                        if (a.nextTrialTime.isBefore(b.nextTrialTime)) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                } else {
                    if (a.isReQ()) {
                        if (a.nextTrialTime.isBefore(now)) {

                            return -1;
                        }
                    } else {
                        if (b.isReQ()) {
                            if (b.nextTrialTime.isBefore(now)) {

                                return -1;
                            }
                        }
                    }

                }

            }

            return 0;




        })

        this._preventRepeatinRow();

    }

    private _preventRepeatinRow() {

        if (this.getLastTrial()) {
            if (this.getLastTrial().data.ID_slowka == this.cards[0].cardID) {
                this.cards.push(this.cards.splice(0, 1)[0])
            }
        }


    }

    public getLastTrial() {
        if (this.dayTrialLog.length > 0) {
            return this.dayTrialLog[0];
        }
        return null;

    }

    getNext(): card {

        this.updateSort();

        this.next = this.cards[0];

        return this.next;

    }

    wordsRevisedCount() {
        let count: number = 0;
        for (let index = 0; index < this.dayTrialLog.length; index++) {
            const trial = this.dayTrialLog[index];
            if (trial.data.sstage == card.SS_reschedule) {
                count++
            }
        }

        return count;
    }

    public updateProgressBarValue() {
        const trialsDone = this.trialsDone(); // console.log(trialsDone);

        const maxTrialsCount = trialsDone + this.leftTrialCount(); // console.log(maxTrialsCount);

        return (trialsDone) / maxTrialsCount;
    }


    addAnswer(cardObj, answerButton: button) {

        if (cardObj == false) {
            throw new Error("All cards for Today done");
        }

        cardObj.reQ(answerButton);

        this.dayTrialLog.unshift(cardObj.trialLog[0]);
        if (cardObj.isResheduled()) {
            this.cards.shift();
        } else {
            this.cards.push(this.cards.shift());
        }
        this.storage.saveAnswer(cardObj, this.courseID);
        // console.table(this.cards);

    }

    getCard(cardID) {
        return this.cards.find(x => x.cardID === cardID);
    }

    getCardFromSets(cardID) {
        for (let index = 0; index < this.sets.length; index++) {
            const list = this.sets[index];
            let res = list.content.find(word => word.word.ID == cardID);
            if (res) {
                return res;
            }

        }
        return false;
    }

    addPoints(event_type: string) {
        this.sessionPointsCount += this._profile.getEventPoints(event_type);
        this.storage.storeSessionPointsForToday(this.courseID, this.sessionPointsCount);
        // this.storage.setData('points_' + this.courseID,this.sessionPointsCount);
    }



}
