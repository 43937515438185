import {Injectable} from '@angular/core';
// import {Http, Headers, Response} from '@angular/http';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class serverService {

    constructor(private http: HttpClient) {
    }

    now: Date;

    private lastSync: Date;

    private syncIvl = 30; // sync interval in seconds

    syncSetArr = [];

    // apiUrl:string = 'http://localhost/lingoquiz/public_html/api/courses/';
    apiUrl = 'https://stage.my.english-line.pl/api/server/';


    dummyJWT = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUuY29tIiwiaWF0IjoiMTM1Njk5OTUyNCIsIm5iZiI6IjEzNTcwMDAwMDAiLCJkYXRhIjp7ImlkIjoiMTM0IiwiZW1haWwiOiJyb2IuYmFydGN6YWtAZ21haWwuY29tIn19.KUV8R572bXqaI4j0jHrxj-tMNOQ9qhNm4V2ppue9wXo';

    /**
     * @deprecated
     * @param curseID
     */
    getDataFromPHP(curseID) {

        // console.log('getting:' + this.apiUrl + 'get?user=134&pass=3284234&kursID='+curseID);

        return this.http.get(this.apiUrl + 'get/' + curseID + '?jwt=' + this.dummyJWT);

    }


    // addProgressToSync(setData) {

    //     console.log('add sync set: ' + setData);

    //     var updated = 0;

    //     for (let index = 0; index < this.syncSetArr.length; index++) {
    //         const element = this.syncSetArr[index];

    //         if (element.id_listy == setData.id_listy) {

    //             var syncData = {
    //                 'ID_pakietu': '956',
    //                 'id_listy': '13378',
    //                 'learnHistory': {
    //                     'ID': '123',
    //                     'ID_sesji': '123',
    //                     'ID_slowka': '2410',
    //                     'ef': 2,
    //                     'id_uzytkownika': '134',
    //                     'interwal': 0,
    //                     'nastepna_powtorka': '2019-02-07T23:00:00.012Z',
    //                     'storage_savetime': '2019-02-07T23:00:00.012Z'
    //                 }
    //             };

    //             this.syncSetArr[index] = syncData;
    //             updated = 1;
    //             console.log('syncArr update with index ' + index);

    //             break;
    //         }

    //     }

    //     if (!updated) {

    //         var syncData = {
    //             'ID_pakietu': '956',
    //             'id_listy': '13378',
    //             'learnHistory': {
    //                 'ID': '123',
    //                 'ID_sesji': '123',
    //                 'ID_slowka': '2410',
    //                 'ef': 2,
    //                 'id_uzytkownika': '134',
    //                 'interwal': 0,
    //                 'nastepna_powtorka': '2019-02-07T23:00:00.012Z',
    //                 'storage_savetime': '2019-02-07T23:00:00.012Z'
    //             }
    //         };
    //         this.syncSetArr.push({
    //             syncData
    //         });
    //     }


    // }

    // postProgress() {
    //     console.log('syncing to server');

    //     this.http.post(this.apiUrl + 'post', JSON.stringify(this.syncSetArr)).subscribe((response) => {
    //         const data: any = response.json();
    //         console.log('result from post ' + data);
    //         this.syncSetArr = [];
    //     });


    // }

    // /**
    //  * Run sync to server every ** seconds
    //  */
    // syncProgress() {
    //     var realNow = moment(new Date());
    //     var now = moment(this.now);
    //     now.hour(realNow.get('hour'));
    //     now.minute(realNow.get('minute'));
    //     now.second(realNow.get('second'));

    //     var now = moment(now);
    //     var syncTime = moment(this.lastSync);
    //     syncTime.add(this.syncIvl, 's');

    //     console.log('now:' + now.toDate());
    //     console.log('now30:' + syncTime.toDate());

    //     if (this.lastSync) {
    //         if (now.isAfter(syncTime)) {

    //             this.postProgress();
    //             this.lastSync = now.toDate();
    //         }
    //     } else {
    //         this.postProgress();
    //         this.lastSync = now.toDate();


    //     }

    //     console.log('last sync:' + this.lastSync);

    // }

    // checkDataSync(checkData: JSON) {
    //     return this.http.post(this.apiUrl + 'checkdata', checkData);
    // }


}
