import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthGuardService {

    constructor(
        private _user: UserService,
        private _router: Router
    ) {
    }

    canActivate(): boolean {
        if (this._user.authenticated) {
            return true;
        }

        this._router.navigate(['login']);
        return false;
    }
}
