import { Pipe, PipeTransform } from '@angular/core';
const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const TOMORROW = 36 * HOUR;
const MONTH = 30 * DAY;

@Pipe({
    name: 'dateToNaturalHowSoon'
})
export class DateToNaturalHowSoon implements PipeTransform {

    transform(value: any, prefix: string = ''): unknown {
        if (!value) {
            return value;
        }

        const asDate = new Date(value);

        const futureTimestamp = asDate.getTime() / 1000;
        // const presentTimestap = new Date('2020-09-25').getTime() / 1000; // Date.now() / 1000;
        const presentTimestap = Date.now() / 1000;

        const timeDiff = futureTimestamp - presentTimestap;

        if (timeDiff <= MINUTE) {
            return 'Jak najszybciej do powtórki';

        } else

        if (timeDiff < DAY) {
            return '';

        } else {
            const toDisplay = this._getDaysDiff(timeDiff);

            return prefix + toDisplay;
        }
    }

    private _getDaysDiff(timeDiff) {
        if (timeDiff > DAY && timeDiff < TOMORROW) {
            return 'Jutro';

        } else if (timeDiff < MONTH) {
            return (timeDiff - timeDiff % DAY) / DAY + ' dni';

        } else {
            return Math.max((timeDiff - timeDiff % MONTH) / MONTH, 1) + ' miesięcy';
        }
    }

    private _getMinutesDiff(timeDiff) {
        if (timeDiff > MINUTE && timeDiff < HOUR) {
            timeDiff = Math.trunc(timeDiff / MINUTE);
            return `${timeDiff}m`;

        } else {
            timeDiff = Math.trunc(timeDiff / HOUR);
            return `${timeDiff}h`;
        }
    }
}
