import {Component, OnInit, Input, OnChanges, OnDestroy} from '@angular/core';
import { Howl } from 'howler';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-audio-file',
    templateUrl: './audio-file.component.html',
    styleUrls: ['./audio-file.component.scss']
})
export class AudioFileComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public audioName: string | number;

    @Input()
    public size = 'normal';

    @Input()
    public set autoplay(value: boolean) {
        this._autoplay = value;
    }
    private _autoplay = false;

    private _audio: Howl;
    public state = {
        disabled: false,
        loading: false,
        ready: false,
        playing: false
    };

    constructor() {

    }

    playSound() {
        if (this.state.ready && !this.state.playing) {
            this._audio.play();
        } else {
            this._audio.pause();
        }
    }

    ngOnChanges() {
        if (this.audioName) {
            this.audioInit();

        } else {
            this.state.loading = false;
            this.state.disabled = true;
            this.state.ready = false;
            this.state.playing = false;
        }

    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        if (this._audio) {
            this._audio.stop();
            this._audio = null;
        }
    }

    audioInit() {
        this.state.loading = true;
        this.state.disabled = true;

        this._audio = new Howl({
            src: [environment.audioFiles + this.audioName],
            preload: true,
            autoplay: this._autoplay,
            onload: () => {
                this.state.disabled = false;
                this.state.loading = false;
                this.state.ready = true;
            },
            onpause: () => {
                this.state.playing = false;
            },
            onplay: () => {
                this.state.playing = true;
            },
            onend: () => {
                this.state.ready = true;
                this.state.playing = false;
            },
            onloaderror: () => {
                this.state.loading = false;
            }
        });
    }
}
