import * as moment from 'moment';
import { storageService } from '../storaga.service';
import { card } from './card';

interface trial {
    ID_slowka:number
    answerType:string
    ef:number
    ivl:number
    lastTrialTime:moment.Moment
    nextTrialTime: moment.Moment
    sTrial:number
    srsstage:number
    sstage:number
  }

  

export class learnTrial {

    data:trial;

    constructor(
        trialData){

            this.data = this._parseData(trialData);
        
    }

    private _parseData(trialData){
        
        var res:any = new Object();

        res['ID_slowka'] = parseInt(trialData['ID_slowka']);
        res['ef']= parseFloat(trialData['ef']);
        res['ivl'] =  parseInt(trialData['interwal']);

        if(trialData['lastTrialTime'] != null){
            if(typeof(trialData['lastTrialTime']) == "string"){
                res['lastTrialTime'] = moment(trialData['lastTrialTime'], storageService.MYSQL_DATETIME);
            }else{
                res['lastTrialTime'] = trialData.lastTrialTime;
            }
            
        }
        if(trialData['nastepna_powtorka']){//parse from backend
            res['nextTrialTime'] = moment(trialData['nastepna_powtorka'], storageService.MYSQL_DATETIME);
        }else if(typeof(trialData.nextTrialTime) == "object"){
            res['nextTrialTime'] = trialData.nextTrialTime;
        }else if(typeof(trialData.nextTrialTime)=="string"){
            res['nextTrialTime'] = moment(trialData['nextTrialTime'], storageService.MYSQL_DATETIME);
        }else{
            console.log('no nextTrialTime');
            
        }
        
        res['sTrial'] =  parseInt(trialData['sTrial']);
        res['srsstage'] =  parseInt(trialData['srsstage']);
        res['sstage'] =  parseInt(trialData['sstage']);
        res['answerType'] =  trialData['answerType'];

        return res;

    }


    public isToday():boolean{
        
        if(this.data.lastTrialTime.isSame(moment() , "day")){
            return true;
        }
        return false;
    }

    public isLearned():boolean{
        if(this.data.sstage == card.SS_reschedule){
            return true;
        }
        return false;
    }

    public scheduledForToday():boolean{
        if(this.data.nextTrialTime.isSameOrBefore(new Date(),"day")){
            return true;
        }
        return false;
    }
    

}