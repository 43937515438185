import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

export interface WordDetails {
    ID: number;
    pytanie: string;
    odpowiedz: string;
    obrazek: string;
    audio: string;
    data_utworzenia: string;
    id_autora: number;
    notatki: string;
    part_of_speech: string;
    rank: number;
}

export interface Word {
    word: WordDetails;
    learnHistory: Array<any>;
    sentences: Array<any>;
}

export interface Set {
    id_listy: string;
    nazwa: string;
    nazwa_pl: string;
    opis: string;
    ID_pakietu: number;
    obrazek: string;
    obrazek_mini: string;
    obrazek_mini2: string;
    data_utworzenia: string;
    autor_listy: number;
    jezyk: string;
    premium: boolean;
    notatki: string;
    status: string;
    poziom: any;
    order: any;
    id: number;
    ID_zestawu: number;
    data_lekcji: string;
    obecnosc: any;
    content: Array<Word>;
    profileEventLog: Array<string>;
    teacherName: string;
    lessonnotes_destination: string;
    cover_pic?: string;
}

@Injectable({
    providedIn: 'root'
})
export class SetsService {
    public data: Array<Set>;

    /* holds the string of ids currently loaded sets */
    private _currentlyLoadedSets: string;

    constructor(
        private _storage: Storage,
    ) {

    }

    public load(setsIds) {
        if (this.data && this._currentlyLoadedSets === JSON.stringify(setsIds)) {
            return Promise.resolve(this.data);
        }

        return this._load(setsIds)
            .then((sets) => {
                if (sets) {
                    this.data = sets;
                    this._currentlyLoadedSets = JSON.stringify(setsIds);
                }

                return sets;
            });
    }

    public store(sets: Set[]) {
        this.data = sets;
        this.data.forEach((set) => this.storeSet(set));
    }

    public storeSet(set) {
        return this._storage.set(set.id_listy, set);
    }

    public getById(id) {
        return this.data.find(item => item.id_listy === id);
    }

    private _load(setIds: number[]) {
        const promises = setIds.map((id) => this._loadFromStorage(id.toString()));

        return Promise.all(promises);
    }


    private _loadFromStorage(id): Promise<Set> {
        return this._storage.get(id) as Promise<Set>;
    }


}
