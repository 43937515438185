import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'name'
})
export class NamePipe implements PipeTransform {

    transform(value: string): any {
        if (!value) {
            return value;
        }

        const arr = value.split(' ');

        if (arr.length > 1) {
            return arr[0] + ' ' + arr[1].substr(0, 1) + '.';
        }

        return value;

    }
}
