import {Injectable} from '@angular/core';
import {CoursesService} from './courses.service';
import {storageService} from '../storaga.service';
import {deck, DeckStatus} from '../models/deck';
import {ProfileService} from './profile.service';
import {ReplaySubject, Subject} from 'rxjs';
import {Utils} from '../classes/Utils';



@Injectable({
    providedIn: 'root'
})
export class GameService {

    static REFRESH_COOLDOWN = 1000 * 60 * 15; // 15 minutes

    private _preparingGame$ = new Subject();
    public preparingGame$ = this._preparingGame$.asObservable();

    private _finishedPreparing$ = new Subject();
    public finishedPreparing$ = this._finishedPreparing$.asObservable();

    public playable = false;
    public deck: deck;

    private _currentCourse: number;

    constructor(
        private _courses: CoursesService,
        private _storage: storageService,
        private _profile: ProfileService
    ) {
    }

    initialize(force = false) {
        if (this._currentCourse !== this._courses.activeCourseId || force) {
            this._currentCourse = this._courses.activeCourseId;

            return this._buildDeck()
                .then(() => {
                    this._finishedPreparing$.next();

                    return this.playable;
                });
        }

        return Promise.resolve(this.playable);
    }

    public hasMoreCards() {
        if (this.deck.getCards().length > 0) {
            return true;
        }
        this.playable = false;

        return false;
    }

    public refresh(force = false) {
        this._preparingGame$.next();
        // const timestamp = this._courses.getActiveCourseUpdateTimestamp();
        // if (!force && Date.now() - timestamp < GameService.REFRESH_COOLDOWN) {
        //     return Promise.reject('Update cooldown...');
        // }

        // if (!Utils.isOnline()) {
        //     return Promise.reject('NO_INTERNET');
        // }

        return this._profile.flushProgressQ()
            .then(() => {
                return this._courses.refresh();
            })
            .then((hasChanges) => {
                // if (hasChanges) {
                    return this._buildDeck();
                // }

                return hasChanges;
            })
            .finally(() => {
                this._finishedPreparing$.next();
                // TODO: call isProgressSynced
            });
    }

    flushProgress() {
        return this._profile.flushProgressQ();
    }

    private _buildDeck() {
        this._preparingGame$.next();
        this.playable = false;
        this.deck = new deck(this._courses.activeCourseId, this._storage, this._profile, this._courses);

        return this.deck.build()
            .then((status) => {
                this.playable = status === DeckStatus.BUILD;

                return this.playable;
            });
    }
}
