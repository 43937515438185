import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ApiService } from './api.service';
import { ReplaySubject, Subject } from 'rxjs';
import { Set, SetsService } from './sets.service';
import { Utils } from '../classes/Utils';
import { storageService } from '../storaga.service';

interface Course {
    data_rozpoczecia: string;
    dlugosc_lekcji: string;
    dni_zajec: string;
    godziny_zajec: string;
    ilosc_lekcji: string;
    image_url: string;
    kurs_ID: number;
    nazwa: string;
    nazwa_pl: string;
    opis: string;
}

const COURSE_SETS_PREFIX = 'course_';
const USER_OWN_COURSE_TYPE = 4;

@Injectable({
    providedIn: 'root'
})
export class CoursesService {
    private _courseChanged$ = new ReplaySubject(1);
    public courseChanged$ = this._courseChanged$.asObservable();

    private _downloadingCourses$ = new Subject();
    public downloadingCourses$ = this._downloadingCourses$.asObservable();

    public userOwnCourse: Course;
    public data: Array<Course> = [];
    public activeCourseId: number;
    public activeCourse: Course;

    private _timestamps = {};
    private _fetchingCourseData = null;

    constructor(
        private _api: ApiService,
        private _storage: storageService,
        private _sets: SetsService
    ) {
        Promise.all([
            this._storage.get('activeCourseId'),
            this._storage.get('rawCoursesData'),
            this._storage.get('coursesTimestamps')
        ]).then(([activeCourseId, rawCoursesData, timestamps]) => {
            if (timestamps) {
                this._timestamps = timestamps;
            }
            if (activeCourseId) {
                this.activeCourseId = activeCourseId;
            }
            if (rawCoursesData) {
                this._processCoursesData(rawCoursesData);
            }
        });
    }

    initialize() {
        return this._downloadCourses();
    }

    public refresh(): Promise<any> {
        return this._hasChanges(this.activeCourseId)
            .then((hasChanges) => {
                if (hasChanges) {
                    return this._fetchSets(this.activeCourseId)
                        .then(() => {
                            return true;
                        });
                }

                return false;
            });
    }

    public getActiveCourseUpdateTimestamp() {
        if (this._timestamps[this.activeCourseId].lastUpdate) {
            return this._timestamps[this.activeCourseId].lastUpdate;
        }

        return 0;
    }

    public setActiveCourse(id = null) {
        this.activeCourseId = id || this.activeCourseId;
        this._storage.set('activeCourseId', this.activeCourseId);
        if (!this._timestamps[this.activeCourseId]) {
            this._timestamps[this.activeCourseId] = {};
        }

        this.activeCourse = this.data.find((elem) => elem.kurs_ID === this.activeCourseId);
        if (this.activeCourse) {
            this._courseChanged$.next();
        }
    }

    public getSetsList(courseId) {
        courseId = courseId || this.activeCourseId;
        return this._getCourseSetsIds(courseId)
            .then((setsIds) => {
                if (!setsIds) {
                    return this._fetchSets(courseId);
                }

                return this._sets.load(setsIds);
            });
    }

    public updateTrialTime(timestamp: number) {
        this._timestamps[this.activeCourseId]['lastTrial'] = timestamp;
        this._storage.set('coursesTimestamps', this._timestamps);
    }

    private _downloadCourses() {
        if (Utils.isOnline() === false) {
            return Promise.resolve();
        }

        return this._api.get('courses/list')
            .then((response) => {
                this._storage.set('rawCoursesData', response);
                this._processCoursesData(response);
            })
            .catch(() => {
                return Promise.resolve();
            });
    }

    private _processCoursesData(rawData) {
        this.userOwnCourse = null;
        this.data = rawData.map(this._adjustCourse);
        this.userOwnCourse = this.data.find((item: any) => +item.typ_kursu === USER_OWN_COURSE_TYPE);

        if (!this.activeCourseId) {
            this.activeCourseId = this.data[0].kurs_ID;
        }
        this.setActiveCourse();
    }

    private _hasChanges(courseId): Promise<boolean> {
        let lastUpdate = 0;
        let lastTrial = 0;
        if (this._timestamps[this.activeCourseId]) {
            lastUpdate = this._timestamps[this.activeCourseId].lastUpdate || 0;
            lastTrial = this._timestamps[this.activeCourseId].lastTrial || 0;
        }

        return this._api.post(`courses/ischanged/${this.activeCourseId}/${lastUpdate}/${lastTrial}`)
            .then((result) => {
                if (result === 1) {
                    return true;
                }

                return false;
            }).catch(() => {
                return Promise.resolve(false);
            });
    }

    /**
     * Mutates each course
     * main reason is typecasting
     * @param course
     * @private
     */
    private _adjustCourse(course) {
        course.kurs_ID = Number(course.kurs_ID);
        return course;
    }

    private _getCourseSetsIds(courseID) {
        const KEY = COURSE_SETS_PREFIX + courseID;

        return this._storage.get(KEY);
    }

    private _fetchSets(courseId): Promise<Array<Set>> {
        if (this._fetchingCourseData === null) {
            this._downloadingCourses$.next();
            this._fetchingCourseData = this._api.get(`courses/get/${courseId}`)
                .then((result) => {
                    let sets = result['courses'][courseId]['zestawy'];
                    sets = Object.values(sets);
                    const sessionPointsObj = result['courses'][courseId]['session_points'];

                    this._storeSets(courseId, sets);
                    this._storage.storeSessionPointsObj(courseId, sessionPointsObj);
                    // Update timestamp and store
                    this._timestamps[courseId]['lastUpdate'] = Date.now();
                    this._storage.set('coursesTimestamps', this._timestamps);

                    return sets;

                })
                .finally(() => this._fetchingCourseData = null);
        }

        return this._fetchingCourseData;
    }


    private _storeSets(courseId, sets) {
        this._sets.store(sets);
        const setsIds = sets.map((set) => Number(set.id_listy));

        const KEY = COURSE_SETS_PREFIX + courseId;
        this._storage.set(KEY, setsIds);
    }

    // Last backend
}
