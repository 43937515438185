import {Component, Renderer2} from '@angular/core';

import {Platform} from '@ionic/angular';
import {Router, ActivatedRoute} from '@angular/router';
import {UserService} from './services/user.service';
import {CoursesService} from './services/courses.service';
import {AppService} from './services/app.service';
import {environment} from '../environments/environment';
import {ProfileService} from './services/profile.service';
import {GameService} from './services/game.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']

})
export class AppComponent {
    public appVersion = environment.version;
    public activeCourseId = 0;

    public canChangeCourse = true;

    constructor(
        private platform: Platform,
        private _renderer: Renderer2,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private _app: AppService,
        public user: UserService,
        public courses: CoursesService,
        public profile: ProfileService,
        private _game: GameService
    ) {
        this.initializeApp();
        this._app.initialized$.subscribe(() => {
            this._hideSplashScreen();
        });

        this._game.finishedPreparing$
            .subscribe(() => {
                this.canChangeCourse = true;
            });

        this._game.preparingGame$
            .subscribe(() => {
                this.canChangeCourse = false;
            });
    }

    selectItem(id: number) {
        this.activeCourseId = id;
        this.courses.setActiveCourse(id);
    }

    initializeApp() {
        this.platform.ready().then(() => {
        });
    }

    private _hideSplashScreen() {
        this._renderer.addClass(document.body, 'initialized');
    }

    logout() {
        this.user.logout()
            .then(() => {
                location.reload(true);
            });
    }

}
