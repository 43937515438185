import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { qParams } from '../../models/qParams';
import { button } from '../../models/button';
import { card } from '../../models/card';
import { storageService } from '../../storaga.service';
import { CoursesService } from '../../services/courses.service';
import { GameService } from '../../services/game.service';

import { ActionSheetController, ToastController, ModalController } from '@ionic/angular';

import { HostListener } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { LearnhistoryModalComponent } from './learnhistory-modal/learnhistory-modal.component';

@Component({
    selector: 'app-study',
    templateUrl: './study.component.html',
    styleUrls: ['./study.component.scss']
})

export class StudyComponent implements OnInit, AfterViewInit {

    public percentage: number;

    title = 'mojeenglishlinews';

    buttonParam;
    reviewParam = 'review 1d';
    hardParam = new qParams('stage 2', 3);
    goodParam = 'review 5d';

    currentCard = [];
    cardFront = true;
    cardBack = false;
    showFront = true;

    show = 'front';

    q = [];
    learnView = true;

    buttons;
    card: card;

    userResponses = [];

    test_days = 60;
    test_idays = 1;

    NoCardsView: boolean;

    wordsArr = [];

    testValue;

    sets;

    progressHeader = {};

    courseID;

    pointsCount: number = 0;

    cardHeight: string | number = '';


    @ViewChild('progressH', { read: ElementRef, static: false }) private progressH: ElementRef;

    @HostListener('document:keypress', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
        if (this.showFront) {
            if (event.key === 'Enter' || event.key === ' ') {
                this.onShow();
            }
        } else if (this.buttons.length === 2 && (event.key === '1' || event.key === '2')) {
            this.onButton(this.buttons[parseInt(event.key, 10) - 1]);
        } else if (this.buttons.length === 3 && (event.key === '1' || event.key === '2' || event.key === '3')) {
            this.onButton(this.buttons[parseInt(event.key, 10) - 1]);
        }

    }

    constructor(
        public actionSheetController: ActionSheetController,
        private storage: storageService,
        private coursesService: CoursesService,
        private _profileService: ProfileService,
        private _game: GameService,
        public toastController: ToastController,
        private _el: ElementRef,
        public modalController: ModalController
    ) {

        this._initDeck();

        this.percentage = this._game.deck.updateProgressBarValue();


    }

    public handleLogin() {
        console.log('Quick study key press');
    }

    async presentActionSheet() {
        const actionSheet = await this.actionSheetController.create({
            header: 'Co chcesz zrobić?',
            buttons: [{
                text: 'Poprzednie',
                role: 'back',
                icon: 'skip-backward',
                handler: () => {
                    // TODO: @robert tutaj logika powrotu do poprzedniego słówka
                    console.log('Back clicked');
                }
            }, {
                text: 'Pomiń',
                role: 'Skip forward',
                icon: 'skip-forward',
                handler: () => {
                    // TODO: @robert tutaj logika przeskakiwnania do następnego słówka
                    console.log('Skip clicked');
                }
            }]
        });
        await actionSheet.present();
    }

    private _initDeck(force = false) {
        this._game.initialize(force)
            .then((playable) => {
                // this.deck = this._game.deck;
                console.log('deck playable: ' + playable);

                if (playable) {
                    this.learnView = true;
                    this.NoCardsView = false;
                    this.nextCard();

                } else {
                    this.NoCardsView = true;
                    this.learnView = false;
                }
            });
    }

    ngAfterViewInit() {
        if (this.card) {
            this.progressH.nativeElement.className = this.card.getProgressStatus();
            this.pointsCount = this._game.deck.sessionPointsCount;
        }
    }

    ngOnInit() {
        // this.storage.now = this.now;
        // storageService.now = this.now;
    }

    ionViewWillEnter() {
        this._resizeCardElement();
    }

    _resizeCardElement() {
        const content = this._el.nativeElement.querySelector('#content');
        const progressBar = this._el.nativeElement.querySelector('#progressBar');
        const ADDITIONAL_SPACING = 15;
        this.cardHeight = content.clientHeight - progressBar.clientHeight - ADDITIONAL_SPACING + 'px';
    }

    assignVar(s: string) {
        this.testValue = s;
        console.log(this.testValue);

    }

    getRandomDays(): number {
        const rnd = Math.random();

        if (rnd < 0.4) {
            return 1;
        }
        if (0.4 <= rnd && rnd < 0.8) {
            return 2;
        }
        if (0.8 <= rnd && rnd <= 1) {
            return 3;
        }

    }

    sleep(milliseconds) {
        const start = new Date().getTime();
        for (let i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > milliseconds) {
                break;
            }
        }
    }

    randomAnswer(buttonArr): button {
        const rnd = Math.random();
        console.log('rand Answer: ' + rnd);

        let ansButton;
        if (buttonArr.length > 2) {
            console.log(('buttons: 3'));

            if (rnd < 0.1) {
                ansButton = buttonArr[0];
            } else if (rnd >= 0.1 && rnd < 0.5) {
                ansButton = buttonArr[1];
            } else {
                ansButton = buttonArr[2];
            }

        } else {
            console.log('buttons: 2' + buttonArr[0]);

            if (rnd < 0.1) {
                ansButton = buttonArr[0];
            } else {
                ansButton = buttonArr[1];
            }

        }

        // console.log('clicked button ' + ansButton.type + ' nextStatus: ' + ansButton.params.nextSstatus + ' Ivl: ' + ansButton.params.ivl + ' EF: ' + ansButton.params.EF);

        return ansButton;

    }


    onShow() {
        this.card.playAudio();
        this.showFront = false;
    }


    nextCard() {
        if (this._game.hasMoreCards()) {
            this.card = this._game.deck.getNext();
            if (this.progressH) {
                this.progressH.nativeElement.className = this.card.getProgressStatus();
            }


            this.currentCard['keyword'] = this.card.keyWord;
            this.currentCard['answer'] = this.card.answer;
            this.currentCard['sentence'] = this.card.sentences[0].tresc;
            this.showFront = true;
            this.buttons = this.card.getButtons();

            this.progressHeader = this._game.deck.progressCounts();
            this.pointsCount = this._game.deck.sessionPointsCount;

            this.percentage = this._game.deck.updateProgressBarValue();
            // console.log('Progress // - ', this.percentage);

            return true;

        } else {
            return false;
        }
    }



    // onNextDay() {
    //     if (this.test_idays < this.test_days) {
    //         this.now = moment(this.now).add(this.getRandomDays(), 'day').toDate();
    //         this._game.deck.setNow(this.now);
    //         console.log('Next day ' + this.now);
    //         this._game.deck.shuffleArr(this._game.deck.cards);
    //         this._game.deck.newSession();
    //         this.test_idays++;
    //         if (!this.nextCard()) {
    //             this.NoCardsView = true;
    //         } else {
    //             this.NoCardsView = false;
    //             this.learnView = true;
    //         }

    //     } else {
    //         this.endSession();
    //     }
    // }

    // updateTime() {
    //     // const realNow = moment(new Date());
    //     const now = moment(new Date());
    //     // now.hour(realNow.get('hour'));
    //     // now.minute(realNow.get('minute'));
    //     // now.second(realNow.get('second'));

    //     // this.now = now.toDate();


    //     // storageService.now = this.now;

    //     // this._game.deck.setNow(this.now);
    // }

    onButton(ansButton) {
        // this.updateTime();

        if (!this._game.hasMoreCards()) {
            this.card.addEvent('all_words_done');
        }

        if (this._profileService.isMiniGoal()) {
            this.card.addEvent('mini_goal');
            this.presentToast('mini_goal');
        }

        this._game.deck.addAnswer(this.card, ansButton);
        this.coursesService.updateTrialTime(this.card.getLastTrial().data.lastTrialTime.unix());

        if (!this.nextCard()) {
            this.endSession();
        }
    }

    endSession() {
        this.progressHeader = this._game.deck.progressCounts();
        this.storage.flushProgress();
        this.learnView = false;
        this.NoCardsView = true;
    }

    onBack() {
        this.storage.flushProgress();
    }

    // onDateChange(event) {

    //     this.now = moment(this.nowDate, moment.ISO_8601).toDate();
    //     this.updateTime();
    //     this._initDeck(true);
    //     console.log('date changed to' + this.now.toString());

    // }

    async presentToast($type) {
        let messageText = 'Powiadomienie'; // we need some default value, otherwise toast will be empty
        if ($type === 'mini_goal') {
            messageText = 'Super. Mini cel osiągnięty.';
        }

        const toast = await this.toastController.create({
            message: messageText,
            duration: 2000
        });
        toast.present();
    }

    openLearnHistoryModal() {
        this.modalController.create({
            component: LearnhistoryModalComponent,
            componentProps: {
                card: this.card
            }
        }).then((modal) => {
            modal.onDidDismiss().then(({ data }) => {

            });

            modal.present();
        });
    }


}
