import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { routes } from './routes';
import { serverService } from './server.service';
import { IonicStorageModule } from '@ionic/storage';
import { storageService } from './storaga.service';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { UserService } from './services/user.service';
import { AppService } from './services/app.service';

import { StudyComponent } from './pages/study/study.component';
import { AppCommonModule } from './common/common.module';
import { LearnhistoryModalComponent } from './pages/study/learnhistory-modal/learnhistory-modal.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [StudyComponent, AppComponent, LearnhistoryModalComponent],
    entryComponents: [LearnhistoryModalComponent],
    imports: [
        CommonModule,
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        HttpClientModule,
        AppCommonModule,
        RouterModule.forRoot(routes, {
            useHash: true,
            relativeLinkResolution: 'legacy'
        }),
        BrowserAnimationsModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        AppService,
        AuthGuardService,
        UserService,
        serverService,
        storageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private _router: Router,
        private _app: AppService
    ) {
    }

}
