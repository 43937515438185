import {
    Pipe,
    PipeTransform
} from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
    name: 'baseUrl'
})
export class BaseUrlPipe implements PipeTransform {

    transform(value: string): any {
        if (!value || value.indexOf('http') === 0) {
            return value;
        }
        return environment.BaseUrl + value;

    }
}
