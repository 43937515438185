import { Routes } from '@angular/router';
import { StudyComponent } from './pages/study/study.component';
import { AuthGuardService } from './services/auth-guard.service';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'study',
        component: StudyComponent,
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module')
            .then(m => m.DashboardPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'lesson/:id',
        loadChildren: () => import('./pages/lesson/lesson.module')
            .then(m => m.LessonPageModule)
    },
    {
        path: 'quiz/:id',
        loadChildren: () => import('./pages/quiz/quiz.module')
            .then(m => m.QuizPageModule)
    },
    {
        path: 'dictate/:id',
        loadChildren: () => import('./pages/dictate/dictate.module')
            .then(m => m.DictatePageModule)
    },
    {
        path: 'my-lessons-list',
        loadChildren: () => import('./my-lessons/pages/my-lessons-list/my-lessons-list.module')
            .then(m => m.MyLessonsListPageModule)
    },
    {
        path: 'my-lessons-list/:id',
        loadChildren: () => import('./my-lessons/pages/my-lessons-list/my-lessons-list.module')
            .then(m => m.MyLessonsListPageModule)
    },
    {
        path: 'my-lesson-details/:id',
        loadChildren: () => import('./my-lessons/pages/my-lesson-details/my-lesson-details.module')
            .then(m => m.MyLessonDetailsPageModule)
    },
    {
        path: 'my-word/:setID/:id',
        loadChildren: () => import('./my-lessons/pages/my-word/my-word.module')
            .then(m => m.MyWordPageModule)
    },
    {
        path: 'my-word',
        loadChildren: () => import('./my-lessons/pages/my-word/my-word.module')
            .then(m => m.MyWordPageModule) },
    {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module')
            .then(m => m.ProfilePageModule) },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module')
            .then(m => m.LoginPageModule) },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module')
            .then(m => m.SettingsPageModule) },
];
