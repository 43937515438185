import { deck } from './deck';
import * as moment from 'moment';
import { button } from './button';
import { Howl, Howler } from 'howler';
import { storageService } from '../storaga.service';
import { environment } from '../../environments/environment';
import { learnTrial } from './trial';


export class card {

    /*
        Spaced Repetition Stage

        0: new // never trialed
        0: relearn
        1: fixedInterval-1 // fixed interval = 1, EF not considered, trail_count > 0
        2: fixedInterval-3 // fixed interval = 2,3, EF not considered
        3: review , interval calculated
    */

    static SRS_New = 0;
    static SRS_fixed1 = 1;
    static SRS_fixed2 = 2;
    static SRS_review = 3;


    /*
    Session stage
       0: notTrialed
       1: trailFailed
       2: trailSuccess
       3: reschedule
    */
    static SS_NotTrialed = 0;
    static SS_trialFailed = 1;
    static SS_trialSuccess = 2;
    static SS_reschedule = 3;

    static mastered_interval = 90;

    front;
    back;
    // now:Date;
    sstage = 0;
    srsstage = 0;
    duedate;
    EF = 2.5; // easiness factor
    trial = 0;
    // interval
    ivl = 0;
    cardID;
    addTime;
    nextTrialTime;
    lastTrialTime: moment.Moment;
    answerType;

    setID: number;

    deckObj: deck;
    wordObj: any;
    WordCreateTime: moment.Moment;

    trialLog: Array<learnTrial> = [];

    profileEventLog = [];

    keyWord;
    answer;

    audioName: string;
    sentenceAudioName: string;


    sentences = [];

    audio;
    sentenceaudio;




    constructor(wordObj, deckObj) {

        this.deckObj = deckObj;
        this.wordObj = wordObj;

        // this.now = now;

        this.setID = wordObj.setID;

        this.cardID = wordObj.word.ID;
        this.keyWord = wordObj.word.pytanie;
        this.answer = wordObj.word.odpowiedz;
        this.sentences = wordObj.sentences;
        this.audioName = wordObj.word.audio;
        this.WordCreateTime = moment(wordObj.word.data_utworzenia, storageService.MYSQL_DATETIME);

        if (this.wordObj.learnHistory.length > 0) {
            this.setTrialLog(this.wordObj.learnHistory);
            this._setupCard();
        }




    }

    private _setupCard() {

        let lastTrial = this.getLastTrial();

        this.EF = lastTrial.data.ef;
        this.ivl = lastTrial.data.ivl;
        this.nextTrialTime = lastTrial.data.nextTrialTime;
        this.lastTrialTime = lastTrial.data.lastTrialTime;
        this.sstage = lastTrial.data.sstage;
        this.srsstage = lastTrial.data.srsstage;
    }


    setup(type) {

        if (this.audioName) {
            this.audio = new Howl({
                src: [environment.audioFiles + this.audioName],
                preload: true
            });
        }

        if (this.sentences[0].audio) {
            this.sentenceAudioName = this.sentences[0].audio;
            this.sentenceaudio = new Howl({
                src: [environment.audioFiles + this.sentences[0].audio],
                preload: true
            });
        }

        if (type === 'revision') {

            if (this._notTrialed()) {
                this._resetStage();
            }

        } else {
            this._initLearning();
        }
    }

    private _initLearning() {
        if (!this.sstage && !this.srsstage) {
            this.sstage = card.SS_NotTrialed;
            this.srsstage = card.SRS_New;
        }
    }

    setTrialLog(learnHistory) {
        learnHistory.forEach(row => {

            let trial = new learnTrial(row);
            this.trialLog.push(trial);

        });
    }

    private _notTrialed(): boolean {
        if (this.sstage == card.SS_reschedule) {
            return true;
        }
        return false;
    }

    // randomDate() {


    //     var date = new Date(+this.now + Math.random() * (this.now.setDate(this.now.getDate() - 3) - this.now.setDate(this.now.getDate() + 3)));
    //     date.setHours(0,0,0,0);
    //     return date;
    //   }

    // setNow(nowDate){
    //     this.now = nowDate;
    // }

    isResheduled() {
        if (this.sstage === 3) {
            return true;
        }
        return false;
    }

    getParams() {
        let logParams = {
            sTrial: this.trial,
            nextTrialTime: this.nextTrialTime.toString(),
            sstage: this.sstage,
            ivl: this.ivl,
            srsstage: this.srsstage,
            ef: this.EF
        };
        return logParams;
    }


    reQ(buttonObj) {

        let params = buttonObj.params;

        // var realNow = moment(new Date());
        let now = moment(new Date());
        // now.hour(realNow.get('hour'));
        // now.minute(realNow.get('minute'));
        // now.second(realNow.get('second'));

        this.nextTrialTime = moment(now).add(params.time.value, params.time.unit);
        this.lastTrialTime = moment(now);
        this.sstage = params.nextSstatus;
        this.ivl = params.ivl;
        this.srsstage = +params.srsstage;
        this.EF = params.EF;
        this.answerType = buttonObj.name;


        this._checkEvent(params);


        let logParams = {
            ID_slowka: this.cardID,
            sTrial: this.trial,
            answerType: buttonObj.name,
            time: moment(now).toDate(),
            nextTrialTime: this.nextTrialTime,
            lastTrialTime: this.lastTrialTime,
            sstage: this.sstage,
            ivl: this.ivl,
            srsstage: +this.srsstage,
            ef: this.EF,
            log: params.log
        };
        this.logTrial(logParams);
        this.trial++;


    }

    _checkEvent(params) {
        let eventType: string;
        if (this.sstage === 3) {
            if (this._successOnFirstTrial()) {
                eventType = 'word_learned_1';
            } else {
                eventType = 'word_learned_x';
            }
            this.addEvent(eventType);
        }

    }

    addEvent(event_type: string) {
        this.profileEventLog.push(event_type);
        this.deckObj.addPoints(event_type);
    }

    _successOnFirstTrial() {
        let count = 0;
        for (let index = 0; index < this.trialLog.length; index++) {
            const element = this.trialLog[index];

            if (moment(element.data.lastTrialTime, storageService.MYSQL_DATETIME).isSame(moment(new Date()), 'day')) {
                count++;
            }
            if (count > 1) {
                return false;
            }

        }
        return true;
    }

    logTrial(params) {
        let trial = new learnTrial(params); // @todo test
        this.trialLog.unshift(trial);
    }

    getLastTrial(): learnTrial {
        if (this.trialLog.length > 0) {
            if (this.trialLog[0].data.lastTrialTime == undefined) {
                console.log('trial undefined');

            }
            return this.trialLog[0];
        }
    }

    /* isRequeued */

    isReQ(): boolean {
        if (this.sstage == 1 || this.sstage == 2) {
            return true;
        }
        return false;
    }

    /**
     * @deprecated
     * use app-audio-file component
     * app/common/components/audio-file
     */
    playSentenceAudio() {
        if (this.sentenceaudio) {
            this.sentenceaudio.play();
        }
    }

    /**
     * @deprecated
     * use app-audio-file component
     * app/common/components/audio-file
     */
    playAudio() {
        if (this.audio) {
            this.audio.play();
        }
    }

    /* get buttons for review
    - show 2 buttons or 3 buttons */
    getButtons(): button[] {

        const ButtonArr = [];

        let params = {
            'nextSstatus': 1,
            'ivl': this.ivl,
            'EF': this.EF,
            'time': { value: 1, unit: 'minute' },
            'srsstage': 0,
            'log': 1,
        };

        let params2 = {
            'nextSstatus': 1,
            'ivl': this.ivl,
            'EF': this.EF,
            'time': { value: 1, unit: 'minute' },
            'srsstage': 0,
            'log': 1,
        };

        let params3 = {
            'nextSstatus': 1,
            'ivl': this.ivl,
            'EF': this.EF,
            'time': { value: 1, unit: 'minute' },
            'srsstage': 0,
            'log': 1,
        };

        if (this.srsstage === card.SRS_New) {
            if (this.sstage === card.SS_NotTrialed || this.sstage === card.SS_trialFailed) {
                params = {
                    'nextSstatus': 1,
                    'ivl': this.ivl,
                    'EF': this.EF,
                    'time': { value: 1, unit: 'minute' },
                    'srsstage': 0,
                    'log': 1,
                };
                var reVButton = new button('review', params);
                ButtonArr.push(reVButton);

                params2 = {
                    'nextSstatus': 2,
                    'ivl': this.ivl,
                    'EF': this.EF,
                    'time': { value: 10, unit: 'minute' },
                    'srsstage': 0,
                    'log': 2,
                };
                var GoodButton = new button('good', params2);
                ButtonArr.push(GoodButton);
            } else if (this.trialSuccess()) {

                params = {
                    'nextSstatus': 1,
                    'ivl': this.ivl,
                    'EF': this.EF,
                    'time': { value: 10, unit: 'minute' },
                    'srsstage': 0,
                    'log': 3,
                };
                var reVButton = new button('review', params);
                ButtonArr.push(reVButton);

                params2 = {
                    'nextSstatus': 3,
                    'ivl': 1,
                    'EF': this.EF,
                    'time': { value: 1, unit: 'day' },
                    'srsstage': 1,
                    'log': 4,
                };
                var GoodButton = new button('good', params2);
                ButtonArr.push(GoodButton);
            }


        } else {
            if (this.sstage === card.SS_trialFailed) {
                if (this.srsstage === 1) {
                    params = {
                        'nextSstatus': 1,
                        'ivl': 1,
                        'EF': this.EF,
                        'time': { value: 10, unit: 'minute' },
                        'srsstage': 1,
                        'log': 5,
                    };
                    ButtonArr.push(new button('review', params));

                    params2 = {
                        'nextSstatus': 3,
                        'ivl': 1,
                        'EF': this.EF,
                        'time': { value: 1, unit: 'day' },
                        'srsstage': 1,
                        'log': 6,
                    };
                    ButtonArr.push(new button('good', params2));

                } else if (this.srsstage === card.SS_trialSuccess) {
                    params = {
                        'nextSstatus': 1,
                        'ivl': 1,
                        'EF': this.EF,
                        'time': { value: 10, unit: 'minute' },
                        'srsstage': 1,
                        'log': 7,
                    };
                    ButtonArr.push(new button('review', params));

                    params2 = {
                        'nextSstatus': 3,
                        'ivl': 1,
                        'EF': this.EF,
                        'time': { value: 1, unit: 'day' },
                        'srsstage': 1,
                        'log': 8,
                    };
                    ButtonArr.push(new button('good', params2));

                } else if (this.srsstage === card.SS_reschedule) {

                    var newEF: number = this.EF;
                    var newivl: number = this.ivl;
                    var log: number;

                    if (this.isMature()) {

                        // var newEF:number = this.getEF(1);//this.EF*0.5;
                        // var newivl:number = this.ivl*this.EF;
                        var newivl: number = this.ivl * 0.5;
                        log = 9.5;

                    } else {
                        var newIvl = 1;
                        // var newEF:number = this.getEF(1);
                        log = 9;
                    }
                    params = {
                        'nextSstatus': 1,
                        'ivl': newivl,
                        'EF': newEF,
                        'time': { value: 10, unit: 'minute' },
                        'srsstage': this.srsstage,
                        'log': log,
                    };
                    ButtonArr.push(new button('review', params));

                    var newEF: number = this.getEF(3);
                    var newIvl: number = this.ivl;

                    params2 = {
                        'nextSstatus': 3,
                        'ivl': newIvl,
                        'EF': newEF,
                        'time': { value: newIvl, unit: 'day' },
                        'srsstage': 3,
                        'log': 10,
                    };
                    ButtonArr.push(new button('hard', params2));

                    var newEF: number = this.getEF(4);
                    var newIvl: number = this.ivl;

                    params3 = {
                        'nextSstatus': 3,
                        'ivl': newIvl,
                        'EF': newEF,
                        'time': { value: newIvl, unit: 'day' },
                        'srsstage': 3,
                        'log': 11,
                    };
                    ButtonArr.push(new button('good', params3));

                }
            } else {//  if(this.sstage == 2){ - triaLFailed
                switch (this.srsstage) {
                    case 1:
                        params = {
                            'nextSstatus': 1,
                            'ivl': this.ivl,
                            'EF': this.EF,
                            'time': { value: 10, unit: 'minute' },
                            'srsstage': 0,
                            'log': 12,
                        };
                        ButtonArr.push(new button('review', params));

                        var newIvl = 2;
                        params2 = {
                            'nextSstatus': 3,
                            'ivl': newIvl,
                            'EF': this.EF,
                            'time': { value: newIvl, unit: 'day' },
                            'srsstage': 2,
                            'log': 13,
                        };
                        ButtonArr.push(new button('hard', params2));

                        var newIvl = 3;
                        params3 = {
                            'nextSstatus': 3,
                            'ivl': newIvl,
                            'EF': this.EF,
                            'time': { value: newIvl, unit: 'day' },
                            'srsstage': 2,
                            'log': 14,
                        };
                        ButtonArr.push(new button('good', params3));
                        break;
                    case 2:
                        params = {
                            'nextSstatus': 1,
                            'ivl': this.ivl,
                            'EF': this.EF,
                            'time': { value: 10, unit: 'minute' },
                            'srsstage': 0,
                            'log': 15,
                        };
                        ButtonArr.push(new button('review', params));

                        var newEF: number = this.getEF(3);
                        var newIvl: number = this.getHardIvl();

                        params2 = {
                            'nextSstatus': 3,
                            'ivl': newIvl,
                            'EF': newEF,
                            'time': { value: newIvl, unit: 'day' },
                            'srsstage': 3,
                            'log': 16,
                        };
                        ButtonArr.push(new button('hard', params2));

                        var newEF: number = this.getEF(4);
                        var newIvl: number = this.getIvl(newEF);

                        params3 = {
                            'nextSstatus': 3,
                            'ivl': newIvl,
                            'EF': newEF,
                            'time': { value: newIvl, unit: 'day' },
                            'srsstage': 3,
                            'log': 17,
                        };
                        ButtonArr.push(new button('good', params3));
                        break;
                    case 3:
                        var srsstage = 1;
                        var log = 18;

                        if (this.isMature()) {

                            // var newEF:number = this.EF*0.2;
                            var newEF: number = this.getEF(2);
                            // var newIvl:number = this.ivl*this.EF;
                            var newIvl: number = this.ivl * 0.5;
                            srsstage = this.srsstage;
                            log = 18.5;
                        } else if (this.trial == 0) {
                            var newIvl = 1;
                            var newEF: number = this.getEF(2);
                        } else {
                            var newIvl = 1;
                            var newEF: number = this.EF;

                        }
                        params = {
                            'nextSstatus': 1,
                            'ivl': newIvl,
                            'EF': newEF,
                            'time': { value: 10, unit: 'minute' },
                            'srsstage': srsstage,
                            'log': log,
                        };
                        ButtonArr.push(new button('review', params));

                        var newEF: number = this.getEF(3);
                        var newIvl: number = this.getHardIvl();

                        params2 = {
                            'nextSstatus': 3,
                            'ivl': newIvl,
                            'EF': newEF,
                            'time': { value: newIvl, unit: 'day' },
                            'srsstage': 3,
                            'log': 19,
                        };
                        ButtonArr.push(new button('hard', params2));

                        var newEF: number = this.getEF(4);
                        var newIvl: number = this.getIvl(newEF);

                        params3 = {
                            'nextSstatus': 3,
                            'ivl': newIvl,
                            'EF': newEF,
                            'time': { value: newIvl, unit: 'day' },
                            'srsstage': 3,
                            'log': 20,
                        };
                        ButtonArr.push(new button('good', params3));


                }
            }
        }





        if (ButtonArr.length < 1) {
            throw new Error('No buttons selected for srsstage' + this.srsstage + ' sstage:' + this.sstage + ' card: ' + this.cardID);
        }

        return ButtonArr;

    }

    public trialSuccess() {
        return this.sstage === 2 ? true : false;
    }

    getEF(q: number): number {
        let newEF = this.EF - 0.8 + 0.28 * q - 0.02 * q * q;
        if (newEF < 1.5) {
            newEF = 1.5;
        }

        return Math.round(newEF * 100) / 100;
    }

    private getCardRevisionDelay() {
        const delay: number = moment().diff(this.getLastTrial().data.nextTrialTime, 'day');
        return delay;
    }

    private _randomizeIvl(ivl) {
        const range = Math.round(ivl * 0.05); // randomize by 5% of the interval
        const maximum = ivl + range;
        let minimum;
        if (ivl > range) {
            minimum = ivl - range;
        } else {
            minimum = ivl;
        }

        return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    }

    getIvl(newEF: number) {
        const newIvl = Math.ceil(this.ivl * newEF) + this.getCardRevisionDelay();
        return this._randomizeIvl(newIvl);
    }

    getHardIvl() {
        let newIvl = Math.floor((0.5 * this.ivl) + (this.ivl + this.getCardRevisionDelay()) * 0.2);
        if (newIvl <= 0) {
            newIvl = 1;
        }
        return this._randomizeIvl(newIvl);
    }

    isMature(): boolean {
        if (this.ivl > 60) {
            return true;
        }
        return false;
    }

    addAnswer(answer) {



    }

    getTrialsLeft() {
        if (this.isNew()) {
            return 2;
        } else if (this.sstage === card.SS_NotTrialed) {
            return 1;
        }

        if (this.sstage === card.SS_trialFailed) {
            return 2;

        }
        if (this.sstage === card.SS_trialSuccess) {
            return 1;
        }
        if (this.sstage == card.SS_reschedule) {
            return 0;
        }
    }

    isTrialedToday() {

        if (this.getLastTrial().isToday()) {
            return true;
        }
        return false;

    }

    getProgressStatus() {

        if (this.isNew()) {
            return 'new';
        }
        if (this.isReq()) {
            return 'req';

        }
        return 'q';

    }

    public isNew() {
        // if (this.sstage === 0 && this.srsstage === 0) {
        if (this.trialLog.length === 0) {
            return true;
        }
        return false;
    }

    public isReq() {
        if (this.sstage === 1 || this.sstage === 2) {
            return true;
        }
        return false;
    }

    public isNewAndLearning() {
        if (this.isReq()) {
            if (this.srsstage === card.SRS_New) {
                const find = this.trialLog.find(trial =>
                    trial.data.sstage === card.SS_reschedule
                );
                if (find) {
                    return false;
                }
                return true;
            }

        }
        return false;
    }

    public isLearned() {
        if (this.sstage === card.SS_reschedule) {
            return true;
        }
        return false;
    }

    public isRevAndLearning() {
        if (this.isReq()) {
            if (!this.isNewAndLearning()) {
                return true;
            }
            // if (this.srsstage != card.SRS_New) {
            return false;
            // }
        }
        return false;
    }

    public isLearnedOnce(): boolean {
        let arr = [];
        if (this.trialLog.length > 0) {
            if (this.getLastTrial().isLearned()) {

                let find = this.trialLog.find(trial =>
                    trial.data.sstage == card.SS_reschedule && !trial.data.lastTrialTime.isSame(this.getLastTrial().data.lastTrialTime)
                );
                if (find) {
                    return false;
                }
                return true;

            }


        }

        return false;

    }

    public scheduledForToday() {
        return this.getLastTrial().scheduledForToday();
    }




    private _resetStage() {
        this.sstage = card.SS_NotTrialed;
        this.lastTrialTime.hour(0);
        this.lastTrialTime.minute(0);
        this.lastTrialTime.second(0);
        this.lastTrialTime.millisecond(0);
    }



}
