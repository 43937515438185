import * as moment from 'moment';

interface ButtonParams {
    time:any;
    nextSstatus:number;
    ivl:number
    srsstage:number
    EF:number

}

export class button {

    type:string;

    params;

    name:string;
    class:string;
    // now:Date;

    constructor(type:string,params:ButtonParams){

        this.type = type;
        switch(this.type){
            case 'review':this.name = 'Powtórz';this.class = 'danger';break;
            case 'hard':this.name = 'Trudno';this.class = 'dark';break;
            case 'good':this.name = 'Dobrze';this.class = 'primary';break;
        }
        this.params = params;
    }

    // setNow(nowDate){
    //     this.now = nowDate;
    // }

    /**
     * @deprecated
     * Moved to src/app/pipes/natural-how-soon.pipe.ts
     */
    ivlText():string{

        var res;
        var value = this.params.time.value;
        var unit = this.params.time.unit;
        if(unit == 'day'){
            if(value == 1){
                res = value + ' dzień';
            }else if(value>1 && value<30){
                res = value + ' dni';
            }else if(value>=30 && value < 365){
                res = this._makeF(value,'month') + ' msc.';
            }else if(value >=365 && value <730){
                res = this._makeF(value,'year') + ' roku';
            }else if(value >=730){
                res = this._makeF(value,'year') + ' lat';
            }
        }else if(unit == 'minute'){
            res = value + ' minut'
        }

        return res;

    }

    nextTrial(){
        var now = new Date();
        var nextTrialTime = moment(now).add(this.params.time.value, this.params.time.unit).toDate();
        return nextTrialTime;
    }

    /**
     * @deprecated
     * Moved to src/app/pipes/natural-how-soon.pipe.ts
     */
    _makeF(value:number,period:string){

        var res;
        switch (period) {
            case 'month':
                res = Math.round(value/30*10)/10;
                break;
            case 'year':
                res = Math.round(value/365*10)/10;
                break;

        }

        var resString:String = res.toString();
        return resString.replace('.',',');

   }

}
