import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Storage } from '@ionic/storage';
import { SetsService } from './sets.service';
import { Utils } from '../classes/Utils';

interface ProfileConfig {
    mini_goal;
    revisions_per_day;
    newwords_per_day;

}

interface ProfileStats {
    repetitionsCountByDay: number[]; // index 0 is today
    wordsLearnedCount: Array<any>;
    pointsCount: number;
    streak: number;
    longestStreak: number;
    wordsLearned: number;
    word_revised_today: number;
    userName: string;
}

interface PointsConfig {
    levels: Array<any>;
    events: Array<any>;
}

interface ProfileData {
    userName: string,
    userId: string
}

interface ProfileInfo {
    profile_config: ProfileConfig;
    profile_stats: ProfileStats;
    points_config: PointsConfig;
    data: ProfileData;
}

const OneSignal = window.OneSignal || [];

@Injectable({
    providedIn: 'root'
})

export class ProfileService {
    public level;
    public nextLevel;
    public wordsByDay = [];

    trialQ = [];

    data: ProfileInfo;

    profileData: Array<any>;
    profileStats: ProfileStats;
    config = {
        mini_goal: 5,
        notifications: true
    };
    pointsConfig: PointsConfig;

    revisionsFinishedToday: 0;

    constructor(
        private _api: ApiService,
        private _storage: Storage,
        private _setsService: SetsService
    ) {
        this._storage.get('ProfileInfo')
            .then((data) => {
                if (data) {
                    this.data = data;
                    this._initNotifications();
                    this._afterProfileLoaded();
                }
            });

    }

    public initialize() {
        this.download()
            .then(() => this._initNotifications());
    }

    private _initNotifications() {
        if (
            window.OneSignal &&
            this.config.notifications &&
            Utils.isOnline() &&
            OneSignal.isPushNotificationsSupported()
        ) {
            this._notificationsEnable();
        }
    }

    public removeUserIdInOneSignal() {
        OneSignal.push(function () {
            OneSignal.removeExternalUserId();
        });
    }

    public getEventPoints(event: string) {
        if (this.data.points_config.events[event]) {
            return this.data.points_config.events[event];
        }
        return 0;
    }

    public getProfileConfig() {
        return this.data.profile_config;
    }

    public changeNotifications() {
        if (this.config.notifications === false) {
            OneSignal.push(['setSubscription', false]);

        } else {
            this._notificationsEnable();
        }
        this._store();
    }

    public changeSetting(event: CustomEvent, name: string) {

        if (typeof (this.data.profile_config[name]) === 'number') {
            this.data.profile_config[name] = +event.detail.value;
        } else {
            this.data.profile_config[name] = event.detail.value;
        }

        this._store().then(() => {
            this._uploadSetting(name);
        });

    }

    private _uploadSetting(name: string) {
        const uploadData = {};
        uploadData['name'] = name;
        uploadData['value'] = this.data.profile_config[name];
        return this._api.post('profile/savesetting', uploadData).then((res) => {

        }, (error) => {

        });
    }

    private _notificationsEnable() {
        OneSignal.push(function () {
            OneSignal.showNativePrompt();
        });
        OneSignal.push(['setSubscription', true]);
        OneSignal.push(() => {
            OneSignal.setExternalUserId(this.data.data.userId);
        });
    }

    private _store() {
        return this._storage.set('ProfileInfo', this.data);
    }

    public download() {
        return this._api.get('profile/get')
            .then((response) => {
                this.data = response;
                this._afterProfileLoaded();
                // console.log('Profile data after download', this.data);

            }).catch((error) => {

            });
    }

    public _afterProfileLoaded() {
        this._parse();
        this._store();
        this._assignLevels();
        this._setRepetitionsCount();
    }

    private _parse() {
        if (typeof (this.data.profile_config.newwords_per_day) === 'string') {
            this.data.profile_config.newwords_per_day = +this.data.profile_config.newwords_per_day;
        }
        if (typeof (this.data.profile_config.revisions_per_day) === 'string') {
            this.data.profile_config.revisions_per_day = +this.data.profile_config.revisions_per_day;
        }

    }

    private _assignLevels() {
        const levels = Object.entries(this.data.points_config.levels)
            .map((lvl) => {
                return {
                    title: lvl[0],
                    points: Number(lvl[1])
                };
            })
            .sort((a, b) => {
                return a.points - b.points;
            });

        let levelIndex = 0;
        for (let i = 1; i < levels.length; i++) {
            if (this.data.profile_stats.pointsCount > levels[i].points) {
                levelIndex = i;
            }
        }

        this.level = levels[levelIndex];
        if (levelIndex < levels.length - 1) {
            this.nextLevel = levels[levelIndex + 1];
        }
    }

    /**
     * Split and concatenate days so they are organized as week days starting with monday
     * @private
     */
    public _setRepetitionsCount() {
        /* 0 - sunday, 1 - monday, ...*/
        let day = (new Date()).getDay();
        const SUNDAY = 6;
        const tmp = [];
        for (const points of this.data.profile_stats.repetitionsCountByDay) {
            tmp.push({
                index: day,
                value: points
            });
            day--;
            if (day < 0) {
                day = SUNDAY;
            }
        }


        this.wordsByDay = tmp;

    }

    addProgress(saveData) {
        this.trialQ.push(saveData);
        if (this._flush()) {
            const saveSlice = this.trialQ.splice(this.trialQ.length - 2, 1)[0];
            this._saveProgress(saveSlice);
        }
    }

    private _flush() {
        if (this.trialQ.length > 1) {
            return true;
        }
        return false;
    }

    _saveProgress(saveData) {

        this._storeAnswer(saveData).then(() => {

            if (Utils.isOnline()) {
                return Promise.all([
                    this._flushUploadQ(),
                    this._uploadProgress(saveData)
                ]);

            } else {
                return this._storeUploadQ(saveData);
            }

        });

    }

    private _storeAnswer(saveData) {

        const set = this._setsService.getById(saveData.setID);

        if (set) {
            const item = set.content.find(itm => itm.word.ID === saveData.ID_slowka);
            if (item) {

                item.learnHistory.unshift(saveData);

                return this._setsService.storeSet(set);
            }
        }

    }


    _flushUploadQ(): Promise<any> {
        return this._storage.get('uploadQ')
            .then((uploadQ) => {
                if (uploadQ != null) {
                    // AppService.regularToast('Wysyłanie postępu');
                    return this._api.post('profile/saveprogress', uploadQ)
                        .then((response) => {
                            this._storage.set('uploadQ', null);
                            console.log((response));
                        });
                }

                return Promise.resolve();
            });
    }

    _storeUploadQ(trial) {
        return this._storage.get('uploadQ')
            .then((uploadQ) => {
                if (uploadQ != null) {
                    uploadQ.push(trial);
                    return this._storage.set('uploadQ', uploadQ);

                } else {
                    const localuploadQ = [];
                    localuploadQ.push(trial);
                    return this._storage.set('uploadQ', localuploadQ);
                }
            });
    }

    isMiniGoal() {
        if (this.revisionsFinishedToday == this.config.mini_goal) {
            return true;
        }
        return false;
    }

    _uploadProgress(trial) {
        const uploadArr = [];
        uploadArr.push(trial);

        return this._api.post('profile/saveprogress', uploadArr)
            .then((response) => {
                // console.log(response);
            });

    }

    flushProgressQ(): Promise<any> {

        if (this.trialQ.length > 0) {
            const promises = this.trialQ.map(trial => {
                const saveSlice = this.trialQ.splice(this.trialQ.length - 2, 1)[0];
                return this._saveProgress(saveSlice);
            });

            return Promise.all(promises)
                .then((result) => {
                    // TODO: Robert, tutaj chyba trzeba wyzerować trialQ ?
                    // this.trialQ.length = 0;
                    return result;

                });
        } else {
            if (Utils.isOnline()) {
                return Promise.all([
                    this._flushUploadQ(),
                ]);

            }
        }


        return Promise.resolve();
    }


}
