import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { MaskPipe } from '../pipes/mask.pipe';
import { AudioFileComponent } from './components/audio-file/audio-file.component';
import { NamePipe } from '../pipes/name.pipe';
import { PercentPipe } from '../pipes/percent.pipe';
import { BaseUrlPipe } from '../pipes/base-url.pipe';
import { NaturalHowSoonPipe } from '../pipes/natural-how-soon.pipe';
import { DateToNaturalHowSoon } from '../pipes/date-to-natural-how-soon';

const DECLARATIONS = [
    AudioFileComponent,
    MaskPipe,
    NamePipe,
    PercentPipe,
    BaseUrlPipe,
    NaturalHowSoonPipe,
    DateToNaturalHowSoon
];

@NgModule({
    imports: [
        CommonModule,
        IonicModule
    ],
    declarations: [DECLARATIONS],
    exports: [DECLARATIONS]
})
export class AppCommonModule { }
