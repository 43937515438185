import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { ReplaySubject } from 'rxjs';
import { CoursesService } from './courses.service';
import { environment } from '../../environments/environment';
import { ProfileService } from './profile.service';
import { ToastController } from '@ionic/angular';
import { Utils } from '../classes/Utils';

@Injectable()
export class AppService {
    private static _instance: AppService;
    public initialized$ = new ReplaySubject(1);
    public deferredPrompt;

    public appInstalled = false;
    public canInstall = false;

    public _deferredPrompt;
    /**
     * @deprecated use Utils.isOnline()
     */
    public static isOnline() {
        Utils.isOnline();
    }

    public static async noConnectionToast() {
        const toast = await AppService._instance._toast.create({
            message: 'Brak połączenia z internetem',
            duration: 2000
        });
        toast.present();
    }

    public static async regularToast(msg) {
        const toast = await AppService._instance._toast.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    constructor(
        private _router: Router,
        private _user: UserService,
        private _profile: ProfileService,
        private _courses: CoursesService,
        public _toast: ToastController
    ) {


        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();

            this.canInstall = true;
            this._deferredPrompt = e;
        });

        window.addEventListener('appinstalled', (event) => {
            this.appInstalled = true;
        });

        AppService._instance = this;
        this._router.navigate(['/login']);

        this._user.initialize()
            .then(() => {
                if (this._user.authenticated) {
                    return this._onAuthenticated();

                } else {
                    this._appInitilized();
                    this._user.authenticated$.subscribe(() => {
                        this._onAuthenticated();
                    });
                }
            });
        this._initOneSignal();
    }

    public addToHomescreen(e: Event) {
        this._deferredPrompt.prompt();
        this._deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    this._deferredPrompt = null;
                    this.appInstalled = true;
                }
            });
    }

    private _appInitilized() {
        this.initialized$.next(true);
        this.initialized$.complete();
    }

    private _onAuthenticated() {
        this._profile.initialize();
        return this._courses.initialize()
            .then(() => this._router.navigate(['dashboard'], { replaceUrl: true }))
            .then(() => {
                setTimeout(() => {
                    this._appInitilized();
                }, 500);
            });
    }

    private _initOneSignal() {
        if (Utils.isOnline()) {
            const OneSignal = window.OneSignal || [];
            OneSignal.push(function () {
                OneSignal.init({
                    appId: environment.OneSignalKey,
                    allowLocalhostAsSecureOrigin: true,
                    requiresUserPrivacyConsent: false,
                    autoResubscribe: true,
                }).catch(function (error) {
                    console.warn(error);
                });
            });
        }
    }
}
